import React from 'react';

// Libraries
import classNames from 'classnames';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';

const CLASS_NAME = 'loci--components--video';

const Video = React.forwardRef<HTMLVideoElement, Spec.Props>(
  (
    {
      autoPlay = true,
      className: _className,
      controls = false,
      controlsList = 'nofullscreen nodownload',
      height = 'auto',
      loop = true,
      playsInline = true,
      preload = 'auto',
      src,
      width = 'auto',
      muted = true,
      ...rest
    },
    ref
  ) => {
    const className = classNames(CLASS_NAME, _className);

    return (
      <video
        {...rest}
        autoPlay={autoPlay}
        className={className}
        controls={controls}
        controlsList={controlsList}
        height={height}
        loop={loop}
        muted
        playsInline={playsInline}
        preload={preload}
        ref={ref}
        width={width}
      >
        <source src={src} type='video/mp4' />
        <track default kind='captions' label='English' srcLang='en' />
      </video>
    );
  }
);

Video.displayName = 'Video';

type VideoProps = Spec.Props;

export { type VideoProps };
export default Video;
