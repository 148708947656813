import { from } from '@apollo/client';

// Fixtures
import { Props as FixturesContextProps } from '@/Fixtures/Context';

import authLink from './authLink';
import errorLink from './errorLink';
import httpLink from './httpLink';
import recentActivityLink from './recentActivityLink';
// import retryLink from './retryLink';

type Props = FixturesContextProps['data'];

/**
 * The order below is ordered in purpose.
 * Please refer to the official documentations for more details
 *
 * https://www.apollographql.com/docs/react/api/link/introduction/#additive-composition
 */
const Links = (props: Props) =>
  from([
    // retryLink,
    errorLink,
    authLink,
    recentActivityLink,
    httpLink(props),
  ]);

export default Links;
