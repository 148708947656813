import React, { PropsWithChildren } from 'react';

// API
import { useUserInfo } from '@/API/Client';

// Router
import { Navigate, useLocation } from '@/Router';

type Props = {
  secured?: boolean;
};

const ProtectedRoute: React.FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  secured = true,
}) => {
  const { userInfo, isValidSession } = useUserInfo();

  const location = useLocation();

  const url = new URL(location.pathname, window.location.origin);

  url.hash = location.hash;
  url.search = location.search;

  const search = new URLSearchParams();

  search.append('view', `${url.pathname}${url.search || url.hash}`);

  const state = {
    view: `${url.pathname}${url.search || url.hash}`,
  };

  if (secured && (!userInfo || !isValidSession)) {
    return (
      <>
        {children}
        <Navigate to='/authentication' state={state} replace />
      </>
    );
  }

  return <>{children}</>;
};

export { type Props };

export default ProtectedRoute;
