import React from 'react';

// Libraries
import classNames from 'classnames';
// import * as Ri from 'react-icons/ri';

// Type
import * as Spec from './spec';

const CLASS_NAME = 'loci--widgets--global-footer--media';

const MEDIAS: Spec.Medias = [
  // {
  //   title: 'YouTube',
  //   icon: Ri.RiYoutubeLine,
  //   href: 'https://www.youtube.com/channel/UCBDReAjsLaiHtBsmbKCKm1A',
  // },
  // {
  //   title: 'Instagram',
  //   icon: Ri.RiInstagramLine,
  //   href: 'https://www.instagram.com/lawrencebugg/?hl=en',
  // },
  // {
  //   title: 'Instagram',
  //   icon: Ri.RiTwitterLine,
  //   href: 'https://twitter.com/bugglawrence?lang=en',
  // },
  // {
  //   title: 'LinkedIn',
  //   icon: Ri.RiLinkedinLine,
  //   href: 'https://www.linkedin.com/in/lawrencebugg/',
  // },
  // {
  //   title: 'Quora',
  //   icon: Ri.RiQuestionLine,
  //   href: 'https://www.quora.com/profile/Lawrence-Bugg-2',
  // },
];

const Media: React.FunctionComponent = () => {
  const className = classNames(
    'loci-layout',
    'loci-layout--full-screen',
    'loci-layout--justify-items--center',
    'loci-layout--justify-content--center',
    'loci-layout--align-content--center',
    'loci-layout--align-items--center',
    'loci-layout--column',
    'loci-layout--gap--wide',
    CLASS_NAME
  );

  if (!MEDIAS?.length) {
    return null;
  }

  return (
    <ul className={className}>
      {MEDIAS?.map((media) => {
        if (!media) {
          return null;
        }

        const { href, icon: Icon, title } = media;

        return (
          <a
            className={classNames('loci-color-white')}
            href={href}
            key={href}
            target={title}
            title={title}
          >
            <Icon className='loci-font-size-medium' />
          </a>
        );
      })}
    </ul>
  );
};

export default Media;
