import React from 'react';

// Libraries
import classNames from 'classnames';

// Components
import { Animation, Heading, HyperLink, Text } from '@/Components';

// Views
import { useLocation } from '@/Router';
import { PATH } from '@/Views/Home';

const CLASS_NAME = 'loci--router--http-status--404';

const _404: React.FunctionComponent = () => {
  const { pathname } = useLocation();

  const className = classNames(
    'loci--router--http-status',
    CLASS_NAME,
    'loci-layout',
    'loci-layout--gap--narrower',
    'loci-layout--row',
    'loci-layout--align-content--center',
    'loci-layout--justify-items--center'
  );

  return (
    <Animation>
      <section className={className}>
        <Heading
          className={classNames(
            'loci-font-size-extreme',
            'loci-font-weight-bolder'
          )}
          is='h1'
        >
          Oops!
        </Heading>
        <Text
          className={classNames(
            'loci-font-size-large',
            'loci-font-weight-bolder'
          )}
        >
          404 - page not found
        </Text>
        <Text className='loci-text-align-center'>
          {'The page '}
          <Text is='span' lookLike='h3'>
            {pathname}
          </Text>
          {
            ' you are looking for might have been removed, had its name changed, or it temporarily unavailable.'
          }
        </Text>
        <Text>
          <HyperLink
            level='confirm'
            lookLikeButton
            to={PATH}
            size='large'
            variant='secondary'
          >
            GO TO HOME PAGE
          </HyperLink>
        </Text>
      </section>
    </Animation>
  );
};

export default _404;
