import React from 'react';

// Libraries
import classNames from 'classnames';

// Analytic
import * as Analytic from '@/Analytic';

// Helpers
import { getHyperLinkClassNames } from '@/Components/HyperLink';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';
import './Styles.level.scss';
import './Styles.size.scss';
import './Styles.variant.scss';

const CLASS_NAME = 'loci--components--button';

const getButtonClassNames = ({
  bold,
  className = '',
  disabled,
  level,
  size,
  unstyled,
  variant = 'primary',
}: Spec.GetButtonClassNamesProps = {}) => {
  return classNames(
    CLASS_NAME,
    'loci-layout',
    'loci-layout--align-content--center',
    'loci-layout--align-items--center',
    'loci-layout--column',
    'loci-layout--justify-content--center',
    'loci-layout--justify-items--center',
    {
      [`${CLASS_NAME}--bold`]: !unstyled && bold,
      [`${CLASS_NAME}--disabled`]: disabled,
      [`${CLASS_NAME}--size--${size}`]: !unstyled && size,
      [`${CLASS_NAME}--level--${level}`]: !unstyled && level,
      [`${CLASS_NAME}--variant--${variant}`]: !unstyled && variant,
      [`${CLASS_NAME}--unstyled`]: unstyled,
    },
    className
  );
};

const Button = React.forwardRef<HTMLButtonElement, Spec.Props>(
  (
    {
      bold,
      children,
      className: _className = '',
      disabled,
      level,
      lookLikeHyperLink = false,
      onClick: clickHandler,
      size,
      type = 'button',
      unstyled,
      variant = 'primary',
      ...rest
    },
    ref
  ) => {
    const className = classNames({
      [getButtonClassNames({
        bold,
        className: _className,
        disabled,
        size,
        level,
        variant,
        unstyled,
      })]: !lookLikeHyperLink,
      [getHyperLinkClassNames()]: lookLikeHyperLink && !unstyled,
      [`${CLASS_NAME}--look-like-hyperlink`]: lookLikeHyperLink && !unstyled,
    });

    const onClick: typeof clickHandler = (event) => {
      Analytic.onClick(event);

      clickHandler?.(event);
    };

    return (
      <button
        {...rest}
        className={className}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        tabIndex={disabled ? -1 : undefined}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = 'Button';

type ButtonProps = Spec.Props;

type GetButtonClassNamesProps = Spec.GetButtonClassNamesProps;

export { type ButtonProps, type GetButtonClassNamesProps, getButtonClassNames };
export default Button;
