// Libraries
import decode, { JwtDecodeOptions } from 'jwt-decode';

// Types
import { Mutation, Query } from '@/API/Client/Types/graphql';

const OPTION: JwtDecodeOptions = {};

type Authorization = Mutation['Activate' | 'SignIn'];

type UserInfo =
  | Partial<
      Exclude<Query['User'], undefined> & {
        exp: number;
        iat?: number;
      }
    >
  | undefined;

const DEFAULT_EXPIRES_IN: Exclude<
  Authorization,
  null | undefined
>['ExpiresIn'] = 0;
const DEFAULT_REFETCH_IN: Exclude<
  Authorization,
  null | undefined
>['RefetchIn'] = 0;

const DEFAULT_USER_INFO: UserInfo = {
  exp: 0,
};

const getUserInfo = (Authorization?: Authorization) => {
  const now = new Date();

  try {
    const {
      AccessToken,
      ExpiresIn = DEFAULT_EXPIRES_IN,
      LastSignedInAt = now,
      RefetchIn = DEFAULT_REFETCH_IN,
      RefreshToken,
    } = Authorization || {};

    const userInfo = decode<UserInfo>(
      String(Authorization?.AccessToken),
      OPTION
    );

    const isContinuance = now.getTime() < new Date(RefetchIn * 1000).getTime();
    const isValidSession = now.getTime() < new Date(ExpiresIn * 1000).getTime();

    return {
      AccessToken,
      Authorization,
      ExpiresIn,
      LastSignedInAt,
      RefetchIn,
      RefreshToken,
      error: undefined,
      isContinuance,
      isValidSession,
      userInfo,
    };
  } catch (error) {
    return {
      AccessToken: undefined,
      Authorization,
      ExpiresIn: DEFAULT_EXPIRES_IN,
      LastSignedInAt: now,
      RefetchIn: DEFAULT_REFETCH_IN,
      RefreshToken: undefined,
      error: error as Error,
      isContinuance: false,
      isValidSession: false,
      userInfo: DEFAULT_USER_INFO,
    };
  }
};

export default getUserInfo;
