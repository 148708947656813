import React, { PropsWithChildren } from 'react';

// Libraries
import {
  NavLink,
  NavLinkProps,
  Navigate,
  NavigateProps,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  ScrollRestoration,
  ScrollRestorationProps,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

// Analytic
import { TagManager } from '@/Analytic';

// Components
import ErrorElement from './ErrorElement';
import * as HttpStatus from './HttpStatus';
import ProtectedRoute from './ProtectedRoute';

const Router: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const router = createBrowserRouter(createRoutesFromElements(children));

  router.subscribe(TagManager.routerSubscriber);

  return <RouterProvider router={router} />;
};

export {
  ErrorElement,
  HttpStatus,
  NavLink,
  Navigate,
  Outlet,
  ProtectedRoute,
  Route,
  Routes,
  ScrollRestoration,
  redirect,
  type NavLinkProps,
  type NavigateProps,
  type ScrollRestorationProps,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
};

export default Router;
