import React, { PropsWithChildren, PropsWithRef } from 'react';

// Libraries
import classNames from 'classnames';

// Components
import { ListItem } from '@/Components';

// Styles
import './Styles.scss';

// Spec
import * as Spec from './Spec';

const CLASS_NAME = 'loci--components--menu';

const ORIENTATIONAL_CLASS_NAMES = {
  horizontal: classNames(
    'loci-layout--column',
    'loci-layout--align-items--center',
    'loci-layout--justify-content--center'
  ),
  vertical: classNames(
    'loci-layout--row',
    'loci-layout--align-content--center',
    'loci-layout--justify-contents--stretch'
  ),
};

const GAPS: Spec.Gap[] = [
  'extreme',
  'narrow',
  'narrower',
  'narrowest',
  'normal',
  'wide',
  'wider',
  'widest',
];

const DEFAULT_GAP: Spec.Gap = 'normal';

const Menu = React.forwardRef<
  HTMLMenuElement,
  Required<PropsWithChildren> & PropsWithRef<Spec.Props>
>(
  (
    {
      children,
      className: _className = '',
      gap: _gap = DEFAULT_GAP,
      orientation = 'horizontal',
      ...rest
    },
    ref
  ) => {
    let gap = _gap;

    if (typeof gap !== 'boolean' && ![...GAPS, undefined].includes(gap)) {
      gap = DEFAULT_GAP;
    }

    const className = classNames(
      'loci-layout',
      {
        'loci-layout--gap--normal': gap === DEFAULT_GAP,
        [`loci-layout--gap--${gap}`]: gap,
      },
      ORIENTATIONAL_CLASS_NAMES[orientation],
      CLASS_NAME,
      _className
    );

    return (
      <menu {...rest} className={className} ref={ref}>
        {React.Children.toArray(children).map((child) => {
          let key = String(child);

          if (React.isValidElement(child)) {
            key = String(child.key);
          }

          return (
            <ListItem className={`${CLASS_NAME}--list-item`} key={key}>
              {child}
            </ListItem>
          );
        })}
      </menu>
    );
  }
);

Menu.displayName = 'Menu';

type MenuProps = Spec.Props;

export { type MenuProps };
export default Menu;
