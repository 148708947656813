import React, { PropsWithChildren, PropsWithRef } from 'react';

// Libraries
import classNames from 'classnames';

// Components
import { Animation, ListItem } from '@/Components';

// Styles
import './Styles.scss';

// Spec
import * as Spec from './Spec';

const CLASS_NAME = 'loci--components--navigation';

const ORIENTATIONAL_CLASS_NAMES = {
  horizontal: classNames(
    'loci-layout--column',
    'loci-layout--align-items--center',
    'loci-layout--justify-content--center'
  ),
  vertical: classNames(
    'loci-layout--row',
    'loci-layout--align-content--center',
    'loci-layout--justify-items--center'
  ),
};

const GAPS: Spec.Gap[] = [
  'extreme',
  'narrow',
  'narrower',
  'narrowest',
  'normal',
  'wide',
  'wider',
  'widest',
];

const DEFAULT_GAP: Spec.Gap = 'normal';

const Navigation = React.forwardRef<
  HTMLElement,
  Required<PropsWithChildren> & PropsWithRef<Spec.Props>
>(
  (
    {
      animation,
      children,
      className: _className = '',
      gap: _gap = DEFAULT_GAP,
      orientation = 'horizontal',
      ...rest
    },
    ref
  ) => {
    let gap = _gap;

    if (typeof gap !== 'boolean' && ![...GAPS, undefined].includes(gap)) {
      gap = DEFAULT_GAP;
    }

    const className = classNames(
      CLASS_NAME,
      'loci-layout',
      ORIENTATIONAL_CLASS_NAMES[orientation],
      {
        'loci-layout--gap--normal': gap === DEFAULT_GAP,
        [`loci-layout--gap--${gap}`]: gap,
      },
      _className
    );

    return (
      <nav {...rest} className={className} ref={ref}>
        {React.Children.toArray(children).map((child, index) => {
          let key = String(child);

          if (React.isValidElement(child)) {
            key = String(child.key);
          }

          const Item = (
            <ListItem className={`${CLASS_NAME}--list-item`} key={key}>
              {child}
            </ListItem>
          );

          if (animation) {
            const animationDelay = (animation.animationDelay || 0) * index;
            const animationEasing =
              animation.animationEasing || 'ease-expo-out';

            return (
              <Animation
                {...animation}
                animationDelay={animationDelay}
                animationEasing={animationEasing}
              >
                {Item}
              </Animation>
            );
          }

          return Item;
        })}
      </nav>
    );
  }
);

Navigation.displayName = 'Navigation';

type NavigationProps = Spec.Props;

export { type NavigationProps };
export default Navigation;
