import React from 'react';

// Libraries
import classNames from 'classnames';

// Spec
import * as Spec from './Spec';

const CLASS_NAME = 'loci--components--heading';

const Heading = React.forwardRef<HTMLHeadingElement, Spec.Props>(
  (
    {
      children,
      className: origin = '',
      is = 'h1',
      variant = 'primary',
      ...rest
    },
    ref
  ) => {
    const className = classNames(
      CLASS_NAME,
      {
        [`loci-heading--variant--${variant}`]: variant,
      },
      origin
    );

    const Component = is;

    return (
      <Component {...rest} className={className} data-is={is} ref={ref}>
        {children}
      </Component>
    );
  }
);

Heading.displayName = 'Heading';

type HeadingProps = Spec.Props;

export { type HeadingProps };
export default Heading;
