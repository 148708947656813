import React, { Suspense } from 'react';

// Components
import { Spinner } from '@/Components';

// Context
import MapProvider from './Context';

// Styles
import 'mapbox-gl/dist/mapbox-gl.css';
import './Styles.scss';

const Contents = React.lazy(() => import('./Contents'));

const Lazy: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<Spinner className='loci--view--full-screen' />}>
      <Contents />
    </Suspense>
  );
};

const Map: React.FunctionComponent = () => {
  return (
    <MapProvider>
      <Lazy />
    </MapProvider>
  );
};

export default Map;
