import { useEffect, useState } from 'react';

type Env = {
  api?: string;
  social?: {
    apple?: {
      client_id?: string;
    };
    google?: {
      client_id?: string;
      scope?: string;
      typeResponse?: string;
    };
  };
  mapbox?: {
    access_token?: string;
  };
};

const getEnv = async () => {
  try {
    const response = await window.fetch(`${window.location.origin}/env`);

    const env = await response.json();

    return env as Env;
  } catch (error) {
    throw new Error(error as string);
  }
};

const useEnv = () => {
  const [data, setData] = useState<Env>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      const data = await getEnv();

      if (!data) {
        throw new Error('Fail to get env');
      }

      setData(data);
    } catch (error) {
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data || loading) {
      return;
    }

    fetch();
  }, [data, loading]);

  return { data, error, fetch, loading };
};

export { getEnv };
export default useEnv;
