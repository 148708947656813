import React from 'react';

// Libraries
import classNames from 'classnames';

// Components
import { Button, ButtonProps } from '@/Components';

// Icons
import { Ri } from '@/Icons';

// Context
import { useSliderContext } from '@/Widgets/Slider/Context';

// Constants
import { CLASS_NAME as DEFAULT_CLASS_NAME } from '@/Widgets/Slider/constants';

// Spec
import * as Spec from './spec';

// Styles
import './Styles.scss';

const CLASS_NAME = classNames(
  'loci-layout',
  'loci-layout--column',
  `${DEFAULT_CLASS_NAME}--thumbnail`
);

const Thumbnail: React.FunctionComponent<Spec.Props> = ({ counts }) => {
  const { currentSlide, instance } = useSliderContext();

  const clickHandler = (index: number) => {
    const onClick: ButtonProps['onClick'] = () => {
      instance?.current?.moveToIdx(index);
    };

    return onClick;
  };

  return (
    <menu className={CLASS_NAME}>
      {Array(counts)
        .fill('')
        .map((_, index) => {
          const key = index;

          const isCurrent = currentSlide === index;

          const Icon = isCurrent
            ? Ri.RiCheckboxBlankCircleFill
            : Ri.RiCheckboxBlankCircleLine;

          let title = 'current slide';

          if (!isCurrent) {
            title = `go to slide ${index}`;
          }

          const className = classNames(
            'loci-layout',
            'loci-layout--justify-items--center',
            'loci-layout--align-content--center'
          );

          return (
            <Button
              aria-disabled={isCurrent}
              aria-pressed={isCurrent}
              className={className}
              key={key}
              onClick={clickHandler(index)}
              title={title}
              unstyled
            >
              <Icon />
            </Button>
          );
        })}
    </menu>
  );
};

export default Thumbnail;
