import { useMutation } from '@/API/Client';

import { Mutation as Schema } from '@/API/Client/Schema';
import { Mutation, MutationActivateArgs } from '@/API/Client/Types/graphql';

type Type = Pick<Mutation, 'Activate'>;

type Props = Parameters<typeof useMutation<Type, MutationActivateArgs>>[1];

const { Activate } = Schema;

const useActivate = (props?: Props) => {
  const [mutate, result] = useMutation<Type, MutationActivateArgs>(
    Activate,
    props
  );

  return { ...result, mutate };
};

export { useActivate, type Type, type Props };
export default Activate;
