// Libraries
import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

const DARK_THEME = 'loci--theme--dark';

const useResponsive = () => {
  const isDarkColorSchemePrefers = useMediaQuery({
    query: '(prefers-color-scheme: dark)',
  });

  const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  const isDesktop = useMediaQuery({ query: '(max-width: 81.24em)' });
  const isTablet = useMediaQuery({ query: '(max-width: 61.24em)' });
  const isMobile = useMediaQuery({ query: '(max-width: 46.24em)' });
  const isNarrow = useMediaQuery({ query: '(max-width: 19.99em)' });
  const isWidescreen = useMediaQuery({ query: '(min-width: 81.25em)' });

  useEffect(() => {
    if (isDarkColorSchemePrefers) {
      document.body.classList.add(DARK_THEME);
      return;
    }

    document.body.classList.remove(DARK_THEME);
  }, [isDarkColorSchemePrefers]);

  return {
    isDarkColorSchemePrefers,
    isDesktop: isDesktop || isWidescreen || !isTablet || !isMobile,
    isLandscape,
    isMobile: isMobile || isNarrow,
    isNarrow,
    isPortrait,
    isTablet: isTablet || isMobile,
    isWidescreen,
  };
};

export default useResponsive;
