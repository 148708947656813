import React, { PropsWithChildren } from 'react';

// Libraries
import { ApolloProvider } from '@apollo/client';

// Fixtures
import { Props as FixturesContextProps } from '@/Fixtures/Context';

// Client
import Client from './Client';

type Props = FixturesContextProps['data'];

const Provider: React.FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  env,
}) => {
  return <ApolloProvider client={Client({ env })}>{children}</ApolloProvider>;
};

export default Provider;
