import { ApolloLink } from '@apollo/client';

// LocalStorage
import { getItem } from '@/LocalStorage';

const COOKIE = 'Authorization';

const authLink = new ApolloLink((operation, forward) => {
  const Authorization = getItem(COOKIE);

  // get the authentication token from cookie storage if it exists
  const authorization = ['bearer', Authorization?.AccessToken];

  // add the authorization to the headers
  operation.setContext(({ headers = {}, ...rest }) => {
    if (!authorization) {
      return { ...rest, headers };
    }

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: authorization.filter(Boolean).join(' '),
      },
    };
  });

  return forward(operation);
});

export default authLink;
