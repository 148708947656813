import React from 'react';

// Libraries
import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

// Variants
import Variants from './Variants';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';

const CLASS_NAME = 'loci--icons--logo';

type LogoProps = Spec.Props;

const TITLE = 'Loci';

const Logo: React.FunctionComponent<LogoProps> = ({
  className: _className = '',
  inline,
  variant = 'name',
}) => {
  const className = classNames(
    'loci-layout',
    'fus-layout--align-content--center',
    'loci-layout--justify-items--center',
    CLASS_NAME,
    {
      [`${CLASS_NAME}--variant--${variant}`]: variant,
      'loci-layout--inline': inline,
    },
    _className
  );

  const src = Variants[variant];

  return (
    <ReactSVG
      aria-label={TITLE}
      className={className}
      src={src}
      title={variant === 'name' ? TITLE : ''}
      wrapper='span'
    />
  );
};

export { type LogoProps, Variants };
export default Logo;
