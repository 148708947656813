import React from 'react';

// API
import { useUserInfo } from '@/API/Client';

// Components
import { Menu, MenuProps } from '@/Components';

// Styles
import './Styles.scss';
import { useResponsive } from '@/Hooks';

const CLASS_NAME = 'loci--widgets--global-header--user';

const Register = React.lazy(() => import('./Authentication'));
const Profile = React.lazy(() => import('./Profile'));

const User: React.FunctionComponent = () => {
  const { isMobile } = useResponsive();
  const { isValidSession } = useUserInfo();

  const gap: MenuProps['gap'] = isMobile ? 'wide' : 'normal';

  return (
    <Menu className={CLASS_NAME} gap={gap}>
      {isValidSession ? <Profile /> : null}
      {!isValidSession ? <Register /> : null}
    </Menu>
  );
};

export default User;
