import { useMutation } from '@/API/Client';

import { Mutation as Schema } from '@/API/Client/Schema';
import { Mutation, MutationSignInArgs } from '@/API/Client/Types/graphql';

type Type = Pick<Mutation, 'SignIn'>;

type Props = Parameters<typeof useMutation<Type, MutationSignInArgs>>[1];

const { SignIn } = Schema;

const useSignIn = (props?: Props) => {
  const [mutate, result] = useMutation<Type, MutationSignInArgs>(SignIn, props);

  return { ...result, mutate };
};

export { useSignIn, type Type, type Props };
export default SignIn;
