import React, { PropsWithChildren, PropsWithRef } from 'react';

// Libraries
import classNames from 'classnames';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';

type ListItemProps = Required<PropsWithChildren> & PropsWithRef<Spec.Props>;

const CLASS_NAME = 'loci--components--list-item';

const gaps: Spec.Gap[] = [
  'normal',
  'extreme',
  'narrow',
  'narrower',
  'narrowest',
  'wide',
  'wider',
  'widest',
];

const DEFAULT_GAP: Spec.Gap = 'normal';

const ListItem = React.forwardRef<HTMLLIElement, ListItemProps>(
  ({ children, gap: _gap, ...rest }, ref) => {
    let gap = _gap;

    if (typeof gap !== 'boolean' && ![...gaps, undefined].includes(gap)) {
      gap = DEFAULT_GAP;
    }

    const className = classNames(
      'loci-layout',
      {
        'loci-layout--gap--normal': gap === DEFAULT_GAP,
        [`loci-layout--gap--${gap}`]: gap,
      },
      CLASS_NAME,
      rest.className
    );

    return (
      <li {...rest} className={className} ref={ref}>
        {children}
      </li>
    );
  }
);

ListItem.displayName = 'ListItem';

export { type ListItemProps };
export default ListItem;
