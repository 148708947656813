import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

// Library
import mapboxGL, { Map as MapRef } from 'mapbox-gl';

// Context
import useEnv from '@/Fixtures/Context/useEnv';

// JSON
import COUNTRIES from './countries.json';

// Spec
import * as Spec from './Spec';

const DEFAULT: Spec.Props = {
  container: { current: null },
  bounds: undefined,
  latitude: undefined,
  loading: true,
  longitude: undefined,
  map: { current: null },
  marker: { current: null },
  setBounds(_) {
    return undefined;
  },
  setLatitude(_) {
    return undefined;
  },
  setLongitude(_) {
    return undefined;
  },
  setZoom(_) {
    return undefined;
  },
  zoom: 5,
};

const Context = React.createContext(DEFAULT);

const MapProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const { data } = useEnv();

  const container = useRef<HTMLDivElement>(null);
  const map = useRef<MapRef>(null);
  const marker = useRef<HTMLDivElement>(null);

  const [bounds, setBounds] = useState<mapboxGL.LngLatBounds | undefined>(
    DEFAULT.bounds
  );
  const [latitude, setLatitude] = useState<number | undefined>(
    DEFAULT.latitude
  );
  const [longitude, setLongitude] = useState<number | undefined>(
    DEFAULT.longitude
  );
  const [loading, isLoading] = useState(DEFAULT.loading);
  const [zoom, setZoom] = useState(DEFAULT.zoom);

  const countries = COUNTRIES.map((country) => {
    if (!marker.current) {
      return {
        ...country,
        marker: undefined,
      };
    }

    const node = new mapboxGL.Marker(marker.current).setLngLat([
      country.longitude,
      country.latitude,
    ]);

    return {
      ...country,
      marker: node,
    };
  });

  useEffect(() => {
    if (!data?.map?.access_token || !container.current) {
      return;
    }

    if (
      map.current // initialize map only once
    ) {
      map.current.on('load', () => {
        isLoading(false);
      });
      map.current.on('move', (event) => {
        if (!map.current) {
          return;
        }

        const bounds = event.target.getBounds();

        setBounds(bounds);
        setLatitude(map.current.getCenter().lng);
        setLongitude(map.current.getCenter().lat);
        setZoom(map.current.getZoom());
      });

      countries.forEach((country) => {
        if (!map.current) {
          return;
        }

        country.marker?.addTo(map.current);
      });

      return;
    }

    if (!latitude || !longitude) {
      navigator.geolocation?.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });

      return;
    }

    mapboxGL.accessToken = data.map.access_token;

    map.current = new mapboxGL.Map({
      center: [longitude, latitude],
      container: container.current,
      style: 'mapbox://styles/loci-admin/clkulzxpg000f01rd1luz0zgd',
      zoom,
    });

    setBounds(map.current.getBounds());
  });

  const value = {
    bounds,
    container,
    latitude,
    loading,
    longitude,
    map,
    setBounds,
    setLatitude,
    setLongitude,
    setZoom,
    zoom,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useMapContext = () => {
  const Contexts = useContext(Context);

  return Contexts;
};

export { useMapContext, COUNTRIES };
export default MapProvider;
