import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

// Libraries
import classNames from 'classnames';

// Router
import { useLocation } from '@/Router';

// Views
import { PATH as HOME_PATH } from '@/Views/Home';

// Widgets
import { SiteLogo } from '@/Widgets';

// Hooks
import { useResponsive } from '@/Hooks';

// Components
import { Animation } from '@/Components';

// Partials
import User from './User';
import Navigation from './Navigation';

// Context
import GlobalHeaderProvider, { useGlobalHeaderContext } from './Context';

// Styles
import './Styles.scss';

const CLASS_NAME = 'loci--widgets--global-header';

const HIDDEN_PATHS = [`/${HOME_PATH}`];

const GlobalHeader: React.FunctionComponent = () => {
  const { node, rect, show, showHeader } = useGlobalHeaderContext();

  const location = useLocation();

  const { isMobile, isTablet } = useResponsive();

  const shouldHide = HIDDEN_PATHS.includes(location.pathname);

  useEffect(() => {
    showHeader(!shouldHide);
  });

  const className = classNames(
    'loci-layout',
    {
      'loci-layout--column': isMobile,
      'loci-layout--gap--normal': !isTablet,
      'loci-layout--gap--wider': isTablet,
      'loci-layout--justify-content--center': !isMobile,
      'loci-layout--justify-content--space-around': isMobile,
      'loci-layout--justify-items--center': !isMobile,
      'loci-layout--row': !isMobile,
    },
    'loci-layout--align-items--center',
    'loci-font-size-small',
    CLASS_NAME
  );

  let Menu = (
    <>
      <Navigation />
      <User />
    </>
  );

  if (isMobile) {
    Menu = (
      <>
        <User />
        <Navigation />
      </>
    );
  }

  const animationDuration = shouldHide ? 'instant' : undefined;

  return (
    <>
      {ReactDOM.createPortal(
        <style id={`${CLASS_NAME}--css-variables`}>
          {`:root {
              --${CLASS_NAME}--block-size: ${rect?.height || 0}px;
            }`}
        </style>,
        window.document.body
      )}
      <Animation
        animationStyle='slide-from-top'
        in={show}
        animationDuration={animationDuration}
      >
        <header className={className} ref={node} role='banner'>
          <SiteLogo className='loci-font-size' withName />
          {Menu}
        </header>
      </Animation>
    </>
  );
};

export { GlobalHeaderProvider, useGlobalHeaderContext };
export default GlobalHeader;
