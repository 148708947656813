import { useQuery } from '@/API/Client';

import { Query as Schema } from '@/API/Client/Schema';
import { Query, QueryImageArgs } from '@/API/Client/Types/graphql';

type Type = Pick<Query, 'Image'>;

type Props = Parameters<typeof useQuery<Type, QueryImageArgs>>[1];

const { Image } = Schema;

const useImage = (props?: Props) => {
  const { data, previousData, loading, error } = useQuery<Type, QueryImageArgs>(
    Image,
    props
  );

  return { data, previousData, loading, error };
};

export { useImage, type Type, type Props };
export default Image;
