// Libraries
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';

// Fixtures
import { Props as FixturesContextProps } from '@/Fixtures/Context';

// Links
import Links from './Links';

// Fragments
import * as Schema from './Schema';

type Props = FixturesContextProps['data'];

const { default: _, ...Fragments } = Schema.Fragment;

const Fragment = Object.values(Fragments);

const Client = (props: Props) =>
  new ApolloClient({
    link: Links(props),
    cache: new InMemoryCache({
      fragments: createFragmentRegistry(...Fragment),
    }),
  });

export default Client;
