import React from 'react';

// Libraries
import classNames from 'classnames';

// Components
import { Animation, AnimationProps } from '@/Components';

// Icons
import { Ri } from '@/Icons';

// Styles
import './Styles.scss';

// Spec
import * as Spec from './Spec';

const CLASS_NAME = 'loci--components--spinner';

const DELAY: AnimationProps['animationDelay'] = 200;
const DURATION: AnimationProps['animationDuration'] = 'fast';

const Spinner: React.FunctionComponent<Spec.Props> = ({
  animationDelay = 0,
  animationStyle = 'zoom-out',
  animationDuration = 'fast',
  className: _className,
  in: transitionIn,
  onEnter,
  onEntered,
  onEntering,
  onExit,
  onExited,
  onExiting,
  position = 'overlay',
  size,
  ...rest
}) => {
  const isOverlay = position === 'overlay';

  const className = classNames(
    CLASS_NAME,
    'loci-layout',
    'loci-layout--align-content--center',
    'loci-layout--align-items--center',
    'loci-layout--justify-content--center',
    'loci-layout--justify-items--center',
    {
      [`${CLASS_NAME}--position--${position}`]: position,
      [`${CLASS_NAME}--size--${size}`]: size,
    },
    {
      'loci-font-size-large': isOverlay,
      'loci-font-size-normal': !isOverlay,
    },
    _className
  );

  const spinnerAnimationDelay = transitionIn ? DURATION : animationDuration;
  const spinnerDelay = transitionIn ? animationDelay + DELAY : animationDelay;

  const wrapperAnimationDelay = transitionIn ? animationDuration : DURATION;
  const wrapperDelay = transitionIn ? animationDelay : animationDelay + DELAY;

  return (
    <Animation
      {...rest}
      animationDelay={wrapperDelay}
      animationDuration={wrapperAnimationDelay}
      in={transitionIn}
    >
      <span className={className} role='progressbar'>
        <Animation
          {...rest}
          animationDelay={spinnerDelay}
          animationDuration={spinnerAnimationDelay}
          animationStyle={animationStyle}
          in={transitionIn}
          onEnter={onEnter}
          onEntered={onEntered}
          onEntering={onEntering}
          onExit={onExit}
          onExited={onExited}
          onExiting={onExiting}
        >
          <span className='loci-layout'>
            <Ri.RiLoader3Line className={`${CLASS_NAME}--icon`} />
          </span>
        </Animation>
      </span>
    </Animation>
  );
};

type SpinnerProps = Spec.Props;

export { type SpinnerProps };
export default Spinner;
