import { useMutation } from '@/API/Client';

import { Mutation as Schema } from '@/API/Client/Schema';
import { Mutation, MutationSocialSignInArgs } from '@/API/Client/Types/graphql';

type Type = Pick<Mutation, 'SocialSignIn'>;

type Props = Parameters<typeof useMutation<Type, MutationSocialSignInArgs>>[1];

const { SocialSignIn } = Schema;

const useSocialSignIn = (props?: Props) => {
  const [mutate, result] = useMutation<Type, MutationSocialSignInArgs>(
    SocialSignIn,
    props
  );

  return { ...result, mutate };
};

export { useSocialSignIn, type Type, type Props };
export default SocialSignIn;
