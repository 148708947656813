import { useMutation } from '@/API/Client';

import { Mutation as Schema } from '@/API/Client/Schema';
import { Mutation, MutationRegisterArgs } from '@/API/Client/Types/graphql';

type Type = Pick<Mutation, 'Register'>;

type Props = Parameters<typeof useMutation<Type, MutationRegisterArgs>>[1];

const { Register } = Schema;

const useRegister = (props?: Props) => {
  const [mutate, result] = useMutation<Type, MutationRegisterArgs>(
    Register,
    props
  );

  return { ...result, mutate };
};

export { useRegister, type Type, type Props };
export default Register;
