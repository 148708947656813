import React from 'react';

// API
import * as API from '@/API/Client';

// Fixtures
import FixturesProvider, { useFixturesContext } from '@/Fixtures/Context';

// Analytic
import { Sentry } from '@/Analytic';

// LocalStorage
import LocalStorageProvider from '@/LocalStorage';

// View
import View from '@/Views';

const Contents: React.FunctionComponent = () => {
  const { data } = useFixturesContext();

  return (
    <LocalStorageProvider>
      <API.Provider env={data.env}>
        <View />
      </API.Provider>
    </LocalStorageProvider>
  );
};

const App: React.FunctionComponent = () => {
  return (
    <FixturesProvider>
      <Contents />
    </FixturesProvider>
  );
};

export default Sentry.withProfiler(App);
