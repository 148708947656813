import React, { Suspense } from 'react';

// Routes
import { Route, ProtectedRoute } from '@/Router';

// Components
import { Spinner } from '@/Components';

// View
import Profile from './Profile';

const PATH = 'user';

const Contents = React.lazy(() => import('./Contents'));

const Lazy: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<Spinner className='loci--view--full-screen' />}>
      <Contents />
    </Suspense>
  );
};

export { PATH };
export default (
  <Route
    path={PATH}
    element={
      <ProtectedRoute>
        <Lazy />
      </ProtectedRoute>
    }
  >
    {Profile}
  </Route>
);
