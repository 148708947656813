import React from 'react';

// Libraries
import classNames from 'classnames';

// Routes
import { Route as Origin } from '@/Router';

// Components
import { Animation, Heading } from '@/Components';

const PATH = 'profile';

const CLASS_NAME = 'loci--view--user--profile';

const About: React.FunctionComponent = () => {
  const className = classNames(
    CLASS_NAME,
    'loci-layout',
    'loci-layout--gap--normal',
    'loci-layout--row',
    'loci-layout--align-content--start'
  );

  return (
    <section className={className}>
      <Animation
        animationDelay={1000}
        animationDuration='slowest'
        animationStyle='slide-from-left'
        unmountOnExit={false}
      >
        <Heading className='loci-font-size-extreme'>Profile</Heading>
      </Animation>
    </section>
  );
};

const Route = <Origin path={PATH} />;

export { PATH, Route };
export default <Origin path={PATH} element={<About />} />;
