import { ApolloError, HttpLink } from '@apollo/client';

// LocalStorage
import { getItem, removeItem, setItem } from '@/LocalStorage';

// Fixtures
import { useFixturesContext } from '@/Fixtures/Context';

// Hooks
import { getUserInfo } from '@/API/Client/Hooks/useUserInfo';

// Mutation
import * as Mutation from '@/API/Client/Mutation';

// Query
import * as Query from '@/API/Client/Query';

// Constants
import { INFO } from './constants';

const getOperationName = (
  document: (typeof Mutation)[keyof typeof Mutation] &
    (typeof Query)[keyof typeof Query]
) =>
  document.definitions.find((definition) => definition.name.kind === 'Name')
    ?.name?.value;

const WHITE_LISTED = [
  getOperationName(Mutation.Activate),
  getOperationName(Mutation.RefreshToken),
  getOperationName(Mutation.Register),
  getOperationName(Mutation.SignIn),
  getOperationName(Mutation.SocialSignIn),
  getOperationName(Query.Image),
];

type Props = ReturnType<typeof useFixturesContext>['data'];

const INVALID_MESSAGES = 'Operation is not allowed';

const NO_READY_MESSAGES = 'Operation is not ready';

const COOKIE = 'Authorization';

const validateAccess = async (props: Props, init: RequestInit) => {
  try {
    const {
      AccessToken,
      Authorization,
      RefreshToken,
      isContinuance,
      isValidSession,
    } = getUserInfo(getItem(COOKIE));

    if (!AccessToken || !RefreshToken) {
      return undefined;
    }

    if (!isValidSession) {
      removeItem('Authorization');
      return undefined;
    }

    if (isContinuance) {
      return Authorization;
    }

    if (!props.env?.api) {
      return undefined;
    }

    if (!Mutation.RefreshToken?.loc?.source?.body) {
      return undefined;
    }

    const operationName = getOperationName(Mutation.RefreshToken);

    const response = await window.fetch(props.env?.api, {
      ...init,
      body: JSON.stringify({
        operationName,
        query: Mutation.RefreshToken.loc.source.body,
        variables: {
          RefreshToken: {
            RefreshToken,
          },
        },
      }),
    });

    const json = await response.json();

    if (!json) {
      return undefined;
    }

    const [error] = json.errors || [];

    if (error) {
      removeItem('Authorization');

      window.location.reload();
    }

    if (!Authorization && !json.data?.RefreshToken?.AccessToken) {
      return undefined;
    }

    setItem('Authorization', {
      ...Authorization,
      ...json.data.RefreshToken,
    });

    return getItem('Authorization');
  } catch (_) {
    return undefined;
  }
};

const httpLink = (props: Props) =>
  new HttpLink({
    credentials: 'include',
    async fetch(_, init) {
      try {
        if (!init) {
          throw new Error(INVALID_MESSAGES);
        }

        const body = JSON.parse(String(init.body || '{}'));

        if (!body.operationName) {
          throw new ApolloError({
            networkError: {
              message: INVALID_MESSAGES,
              name: INFO.MISSING_OPERATION_NAME,
            },
          });
        }

        if (!props.env?.api) {
          return Promise.reject(NO_READY_MESSAGES);
        }

        if (WHITE_LISTED.includes(body.operationName)) {
          return window.fetch(props.env.api, init);
        }

        let Authorization = getItem('Authorization');

        if (!WHITE_LISTED.includes(body.operationName)) {
          Authorization = await validateAccess(props, init);
        }

        if (!Authorization) {
          throw new ApolloError({
            networkError: {
              message: INVALID_MESSAGES,
              name: INFO.NOT_AUTHORIZE,
            },
          });
        }

        const result = await window.fetch(props.env.api, {
          ...init,
          headers: {
            ...init.headers,
            authorization: `bearer ${Authorization.AccessToken}`,
          },
        });

        return result;
      } catch (error) {
        throw new ApolloError(error as ApolloError);
      }
    },
  });

export default httpLink;
