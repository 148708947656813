import React, { PropsWithChildren, useContext } from 'react';

import useEnv from './useEnv';

type Data = {
  env: ReturnType<typeof useEnv>['data'];
};

type Props = Omit<ReturnType<typeof useEnv>, 'data'> & {
  data: Data;
};

const DEFAULT: Props = {
  data: {
    env: undefined,
  },
  error: undefined,
  loading: false,
  async fetch() {
    return undefined;
  },
};

const Context = React.createContext(DEFAULT);

const FixturesProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const env = useEnv();

  const data = {
    env: env.data,
  };

  const { error, fetch, loading } = env;

  const value = {
    data,
    error,
    fetch,
    loading,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useFixturesContext = () => {
  const Contexts = useContext(Context);

  return Contexts;
};

export { useFixturesContext, type Props };
export default FixturesProvider;
