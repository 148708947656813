import React, { Suspense } from 'react';

// Routes
import { Route as Origin } from '@/Router';

// Components
import { Spinner } from '@/Components';

// Partials
import Activate from './Activate';
import Social from './Social';

// Constants
import { PATH, CALLBACK_PATH, SIGN_OUT_PATH, SOCIAL_PATH } from './constants';

const Contents = React.lazy(() => import('./Contents'));

const Lazy: React.FunctionComponent = () => {
  return (
    <Suspense fallback={<Spinner className='loci--view--full-screen' />}>
      <Contents />
    </Suspense>
  );
};

export { PATH, CALLBACK_PATH, SIGN_OUT_PATH, SOCIAL_PATH };
export default (
  <Origin path={PATH}>
    {Activate}
    {Social}
    <Origin path='' element={<Lazy />} />
  </Origin>
);
