import React, { useEffect } from 'react';

// Routes
import Router, {
  ErrorElement,
  HttpStatus,
  Outlet,
  Route,
  ScrollRestoration,
  useLocation,
  useMatch,
} from '@/Router';

// Widgets
import {
  GlobalHeader,
  GlobalHeaderProvider,
  GlobalFooter,
  GlobalFooterProvider,
} from '@/Widgets';

// Hooks
import { useResponsive } from '@/Hooks';

// Views
import Authentication from './Authentication';
import Home, { PATH as HOME_PATH } from './Home';
import Map from './Map';
import Privacy from './Privacy';
import TermsOfService from './TermsOfService';
import User from './User';

// Styles
import './Styles.scss';

const Contents: React.FunctionComponent = () => {
  return (
    <>
      <ScrollRestoration />
      <GlobalHeader />
      <main className='loci--view'>
        <Outlet />
      </main>
      <GlobalFooter />
    </>
  );
};

const Element: React.FunctionComponent = () => {
  useResponsive();

  const location = useLocation();

  const match = useMatch(HOME_PATH);

  useEffect(() => {
    const root = document.querySelector('body');

    if (!root) {
      return;
    }

    const routes = (location.pathname.replace('/', '') || 'home').split('/');

    root.dataset.routes = routes.join('.');

    document.title = `Loci - Your Local Tour Guide | ${routes.join(' | ')}`;
  });

  return (
    <GlobalHeaderProvider>
      <GlobalFooterProvider isFullScreenMode={!!match}>
        <Contents />
      </GlobalFooterProvider>
    </GlobalHeaderProvider>
  );
};

const App: React.FunctionComponent = () => {
  return (
    <Router>
      <Route path='*' errorElement={<ErrorElement />} element={<Element />}>
        <Route path='*' element={<HttpStatus.Status404 />} />
        {Authentication}
        {Home}
        {Map}
        {Privacy}
        {TermsOfService}
        {User}
      </Route>
    </Router>
  );
};

export default App;
