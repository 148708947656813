import React, { PropsWithChildren, PropsWithRef } from 'react';

// Libraries
import classNames from 'classnames';

// Spec
import * as Spec from './Spec';

// Styles
import './Styles.scss';

type ListProps = Required<PropsWithChildren> & PropsWithRef<Spec.Props>;

type ListNode = HTMLOListElement & HTMLUListElement;

const CLASS_NAME = 'loci--components--list';

const GAPS: Spec.Gap[] = [
  'extreme',
  'narrow',
  'narrower',
  'narrowest',
  'normal',
  'wide',
  'wider',
  'widest',
];

const DEFAULT_GAP: Spec.Gap = 'normal';

const List = React.forwardRef<ListNode, ListProps>(
  ({ children, gap: _gap, is = 'ul', ...rest }, ref) => {
    let gap = _gap;

    if (typeof gap !== 'boolean' && !GAPS.includes(gap)) {
      gap = DEFAULT_GAP;
    }

    const className = classNames(
      CLASS_NAME,
      'loci-layout',
      {
        'loci-layout--gap--normal': gap === DEFAULT_GAP,
        [`loci-layout--gap--${gap}`]: gap,
      },
      rest.className
    );

    const Component = is;

    return (
      <Component {...rest} className={className} data-is={is} ref={ref}>
        {children}
      </Component>
    );
  }
);

List.displayName = 'List';

export { type ListProps, type ListNode };
export default List;
