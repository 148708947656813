// LocalStorage
import { useLocalStorageContext } from '@/LocalStorage';

// Utilities
import { getUserInfo } from '@/API/Client/Utilities';

// Types
import { Mutation } from '@/API/Client/Types/graphql';

type Authorization = Mutation['Activate' | 'SignIn'];

const COOKIE = 'Authorization';

const useUserInfo = () => {
  const { getItem } = useLocalStorageContext();

  const Authorization: Authorization = getItem(COOKIE);

  const UserInfo = getUserInfo(Authorization);

  return UserInfo;
};

export { getUserInfo };
export default useUserInfo;
