import ReactGA, { EventArgs, GaOptions, Tracker } from 'react-ga';

const gaOptions: GaOptions = {
  siteSpeedSampleRate: 100,
};

const Tracker: Tracker = {
  debug: import.meta.env.DEV,
  gaOptions,
  standardImplementation: true,
  testMode: import.meta.env.DEV,
  trackingId: import.meta.env.VITE_GA_TRACKING_CODE,
};

const init = () => {
  ReactGA.initialize([Tracker]);
  ReactGA.pageview(`${window.location.pathname}${window.location.search}`);
};

const { event } = ReactGA;

export { type EventArgs, event, init };
export default {};
