import { useMutation } from '@/API/Client';

import { Mutation as Schema } from '@/API/Client/Schema';
import { Mutation, MutationRefreshTokenArgs } from '@/API/Client/Types/graphql';

type Type = Pick<Mutation, 'RefreshToken'>;

type Props = Parameters<typeof useMutation<Type, MutationRefreshTokenArgs>>[1];

const { RefreshToken } = Schema;

const useRefreshToken = (props?: Props) => {
  const [mutate, result] = useMutation<Type, MutationRefreshTokenArgs>(
    RefreshToken,
    props
  );

  return { ...result, mutate };
};

export { useRefreshToken, type Type, type Props };
export default RefreshToken;
